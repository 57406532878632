import React, { useState, useEffect } from "react";
import axios from "axios";
import { auth } from "../../../authentication/firebase";
import {
  CheckCircleIcon,
  ShoppingCartIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import loadingImage from "../../../Multimedia/img/loading.gif";
import VentasList from "./VentasList";
import API_URL from "../../../authentication/api";

import {
  Card,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@tremor/react";

function VentasForm({ typeUser, correoUser }) {
  const [key, setKey] = useState(0);

  const handleTabChange = (index) => {
    // Cada vez que cambiamos de pestaña, incrementamos la clave
    setKey((prevKey) => prevKey + 1);
  };

  return (
    <>
      <TabGroup defaultIndex={0} onChange={handleTabChange} className="dark:bg-stone-300 p-0 m-0 rounded-xl">
        <TabList className="pt-2" variant="line">
          <Tab icon={ShoppingCartIcon}>Registrar Venta</Tab>
          <Tab icon={UsersIcon}>Lista de Ventas</Tab>
        </TabList>
        <TabPanels>
          <TabPanel >
            <TableBase key={key} typeUser={typeUser} correoUser={correoUser} />
          </TabPanel>
          <TabPanel>
            <VentasList key={key} typeUser={typeUser} correoUser={correoUser} />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </>
  );
}
export default VentasForm;

const TableBase = ({ typeUser, correoUser }) => {
  const clienteInicial = {
    DNI: "",
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    numeroCelular: "",
    email: "",
    direccion: "",
    estadoCivil: "",
    fechaNacimiento: "",
  };
  const ventaInicial = {
    idVendedor: 0,
    idCliente: 0,
    idTerreno: 0,
    totalImportePago: "",
    pagoImporteInicial: "",
    fechaPagoInicial: "",
    fechaPagoFinal: "",
  };

  const [cliente, setCliente] = useState(clienteInicial);
  const [proyectos, setProyectos] = useState([]);
  const [terrenos, setTerrenos] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [vendedorInicial, setVendedorInicial] = useState({});
  const [terrenosFiltrados, setTerrenosFiltrados] = useState([]);
  const [venta, setVenta] = useState(ventaInicial);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchDatos = async () => {
      try {
        const resProyectos = await axios.get(`${API_URL}/proyectos`);
        setProyectos(
          resProyectos.data.filter((proyecto) => proyecto.estado === "activo")
        );

        const resTerrenos = await axios.get(`${API_URL}/terrenos`);
        setTerrenos(resTerrenos.data);

        const resVendedores = await axios.get(`${API_URL}/vendedores`);
        const vendedoresActivos = resVendedores.data.filter(
          (v) => v.typeUser !== "desactivo"
        );
        setVendedores(vendedoresActivos);
        const vendedor = resVendedores.data.find(
          (v) => v.email === auth.currentUser.email
        );
        setVendedorInicial(vendedor);
        setVenta((v) => ({ ...v, idVendedor: vendedor.id }));
        // setVenta({ ...venta, idVendedor: vendedor.id });
        // console.log(resVendedores.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    // console.log(auth.currentUser.email);
    fetchDatos();
  }, []);

  const handleProyectoChange = (e) => {
    const proyectoSeleccionado = Number(e.target.value);

    const filtrarTerrenos = terrenos.filter(
      (terreno) =>
        terreno.idProyecto === proyectoSeleccionado &&
        terreno.estado === "disponible"
    );
    setTerrenosFiltrados(filtrarTerrenos);
  };

  const handleFormSubmitCliente = async (e) => {
    e.preventDefault();
    try {
      if (!cliente.id) {
        const res = await axios.post(`${API_URL}/clientes`, cliente);
        setVenta({ ...venta, idCliente: res.data.id });
        setCliente({ ...cliente, idCliente: res.data.id });
      } else {
        const res = await axios.put(
          `${API_URL}/clientes/${cliente.id}`,
          cliente
        );
        console.log(res)
        setVenta({ ...venta, idCliente: cliente.id });
      }

      setIsVisible(true);
    } catch (error) {
      console.error(error);
    }
  };
  const handleFormSubmitVenta = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_URL}/ventas`, venta);
      // Resetear los formularios a su estado inicial
      setCliente(clienteInicial);
      setVenta(ventaInicial);
      setVenta({ ...venta, idVendedor: vendedorInicial.id });

      var successMessage = document.getElementById("successMessage");
      successMessage.style.display = "block";

      // Ocultar el mensaje de éxito después de 1 segundo
      setTimeout(function () {
        successMessage.style.opacity = "0";
        successMessage.style.transition = "opacity 1s ease-out";

        // Después de la transición, ocultar el elemento por completo
        setTimeout(function () {
          successMessage.style.display = "none";
          successMessage.style.opacity = "1";
          successMessage.style.transition = "";
        }, 1000);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
    seleccionarOpcionCero();
  };

  const buscarCliente = async () => {
    try {
      const res = await axios.get(`${API_URL}/clientes`);
      const clienteBuscado = res.data.find((c) => c.DNI === cliente.DNI);
      if (clienteBuscado) {
        setCliente(clienteBuscado);
      } else {
        alert("Cliente no encontrado");
        setCliente({ ...clienteInicial, DNI: cliente.DNI });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleVendedorChange = (e) => {
    setVenta({ ...venta, idVendedor: Number(e.target.value) });
  };

  function seleccionarOpcionCero() {
    const selectProyectoElement = document.getElementById("proyectoSelect");
    selectProyectoElement.value = "0";

    const selectManzanaElement = document.getElementById("manzanaSelect");
    selectManzanaElement.value = "0";

    const selectLoteElement = document.getElementById("loteSelect");
    selectLoteElement.value = "0";

    setVenta({...venta, idTerreno:"",totalImportePago: "",
    pagoImporteInicial: "",
    fechaPagoInicial: "",
    fechaPagoFinal: ""})

    setIsVisible(false);
  }

  return (
    <>
      {loading ? (
        <div className="flex mt-10 items-start justify-center h-screen">
          <img
            width="200px"
            height={"auto"}
            src={loadingImage}
            alt="Cargando..."
            className="rounded-full border border-gray-300 p-1 animate-fade-in-out duration-1000"
          />
        </div>
      ) : (
        <Card className="bg-slate-50 dark:bg-stone-100">
          <div className="flex justify-center items-center relative">
            <div
              id="successMessage"
              className="card text-bg-success mb-3"
              style={{
                maxWidth: "18rem",
                display: "none",
                position: "absolute",
                top: "30vh",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <div className="card-header flex">
                <CheckCircleIcon width="25px" className="mr-2" />
                Exito
              </div>
              <div className="card-body">
                <h5 className="card-title">Venta Subida Exitosamente</h5>
              </div>
            </div>
          </div>

          <div className="block lg:flex justify-start align-middle">
            <form className="max-w-md mx-auto p-2">
              {/* Datos del Vendedor */}
              <>
                <h2 className="text-1xl mt-2 font-bold mb-1">
                  Datos del Vendedor
                </h2>
                {(typeUser === "junior" || typeUser === "avanzado") && (
                  <div>
                    <span className="font-bold">Vendedor: </span>{" "}
                    <span>
                      {vendedorInicial.nombre} {vendedorInicial.apellido}
                    </span>
                  </div>
                )}
                {typeUser !== "junior" && typeUser !== "avanzado" && (
                  <select
                    className="w-full px-1 py-2 mb-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={handleVendedorChange}
                  >
                    <option key={vendedorInicial.id} value={vendedorInicial.id}>
                      {vendedorInicial.email} - {vendedorInicial.nombre} {vendedorInicial.apellido}
                    </option>
                    {vendedores.map((vendedor) => (
                      <option key={vendedor.id} value={vendedor.id}>
                        {vendedor.email} - {vendedor.nombre} {vendedor.apellido}
                      </option>
                    ))}
                  </select>
                )}
              </>

              {/* Datos del Cliente */}
              <>
                <h2 className="text-1xl mt-2 font-bold mb-1">
                  Datos del Cliente
                </h2>
                <div className="flex">
                  <input
                    type="text"
                    value={cliente.DNI}
                    onChange={(e) =>
                      setCliente({ ...cliente, DNI: e.target.value })
                    }
                    placeholder="DNI*"
                    className="mr-1 w-full px-1 py-2 mb-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                  <button
                    type="button"
                    onClick={buscarCliente}
                    className="ml-1 px-1 py-2 mb-1 text-white bg-blue-500 rounded-md hover:bg-blue-600"
                  >
                    Buscar
                  </button>
                </div>
                <input
                  type="text"
                  value={cliente.nombres}
                  onChange={(e) =>
                    setCliente({ ...cliente, nombres: e.target.value })
                  }
                  placeholder="Nombres*"
                  className="w-full px-1 py-2 mb-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
                <div className="flex">
                  <input
                    type="text"
                    value={cliente.apellidoPaterno}
                    onChange={(e) =>
                      setCliente({
                        ...cliente,
                        apellidoPaterno: e.target.value,
                      })
                    }
                    placeholder="Ap. Paterno*"
                    className="w-full px-1 py-2 mb-1 mr-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                  <input
                    type="text"
                    value={cliente.apellidoMaterno}
                    onChange={(e) =>
                      setCliente({
                        ...cliente,
                        apellidoMaterno: e.target.value,
                      })
                    }
                    placeholder="Ap. Materno*"
                    className="w-full px-1 py-2 mb-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>
                <input
                  type="text"
                  value={cliente.numeroCelular}
                  onChange={(e) =>
                    setCliente({ ...cliente, numeroCelular: e.target.value })
                  }
                  placeholder="Número de Celular*"
                  className="w-full px-1 py-2 mb-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
                <input
                  type="email"
                  value={cliente.email}
                  onChange={(e) =>
                    setCliente({ ...cliente, email: e.target.value })
                  }
                  placeholder="Email (opcional)"
                  className="w-full px-1 py-2 mb-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <input
                  type="text"
                  value={cliente.direccion}
                  onChange={(e) =>
                    setCliente({ ...cliente, direccion: e.target.value })
                  }
                  placeholder="Dirección (opcional)"
                  className="w-full px-1 py-2 mb-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <input
                  type="text"
                  value={cliente.estadoCivil}
                  onChange={(e) =>
                    setCliente({ ...cliente, estadoCivil: e.target.value })
                  }
                  placeholder="Estado Civil (opcional)"
                  className="w-full px-1 py-2 mb-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <label className="mt-2">
                  Fecha de Nacimiento: {"(opcional)"}
                  <input
                    type="date"
                    value={
                      cliente.fechaNacimiento
                        ? new Date(cliente.fechaNacimiento)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    onChange={(e) =>
                      setCliente({
                        ...cliente,
                        fechaNacimiento: e.target.value,
                      })
                    }
                    placeholder="Fecha de Nacimiento"
                    className="w-full px-1 py-2 mb-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 "
                  />
                </label>
              </>
              <div className="text-center">
                <button
                  type="button"
                  onClick={handleFormSubmitCliente}
                  className="px-1 py-2 mb-1 text-white bg-purple-500 dark:bg-gray-600 dark:hover:bg-gray-700 rounded-md hover:bg-purple-600"
                >
                  <span className="p-8">Enviar</span>
                </button>
              </div>
            </form>

            {isVisible && (
              <form
                onSubmit={handleFormSubmitVenta}
                className="p-2 space-y-4 max-w-md mx-auto"
              >
                <div className="space-y-2">
                  <h2 className="text-2xl font-bold">Proyecto</h2>
                  <select
                    id="proyectoSelect"
                    onChange={handleProyectoChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  >
                    <option key=" " value="0">
                      - seleccione -
                    </option>
                    {proyectos.map((proyecto) => (
                      <option key={proyecto.id} value={proyecto.id}>
                        {proyecto.nombre}
                      </option>
                    ))}
                  </select>
                </div>

                {/* <div className="space-y-2">
                  <h2 className="text-2xl font-bold">Terreno</h2>
                  <select
                    id="terrenoSelect"
                    onChange={(e) =>
                      setVenta({ ...venta, idTerreno: Number(e.target.value) })
                    }
                    className="w-full p-2 border border-gray-300 rounded-md"
                  >
                    <option key=" " value="0">
                      - seleccione -
                    </option>
                    {terrenosFiltrados.map((terreno) => (
                      <option key={terreno.id} value={terreno.id}>
                        {terreno.manzana + "-" + terreno.lote}
                      </option>
                    ))}
                  </select>
                </div> */}

                <div className="flex space-x-2">
                  <select
                    id="manzanaSelect"
                    onChange={(e) =>
                      setVenta({ ...venta, manzana: e.target.value })
                    }
                    className="w-full p-2 border border-gray-300 rounded-md"
                  >
                    <option key=" " value="0">
                      - seleccione manzana -
                    </option>
                    {
                      [...new Set(terrenosFiltrados.map((terreno) => terreno.manzana))].map((manzana) => (
                        <option key={manzana} value={manzana}>
                          {manzana}
                        </option>
                      ))
                    }
                  </select>

                  <select
                    id="loteSelect"
                    onChange={(e) => {
                      const loteSeleccionado = e.target.value;
                      const terrenoSeleccionado = terrenosFiltrados.find(
                        (terreno) => terreno.manzana === venta.manzana && terreno.lote === loteSeleccionado
                      );
                      setVenta({ ...venta, lote: loteSeleccionado, idTerreno: terrenoSeleccionado.id });
                    }}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  >
                    <option key=" " value="0">
                      - seleccione lote -
                    </option>
                    {
                      terrenosFiltrados
                        .filter(terreno => terreno.manzana === venta.manzana)
                        .map((terreno) => (
                          <option key={terreno.id} value={terreno.lote}>
                            {terreno.lote}
                          </option>
                        ))
                    }
                  </select>
                </div>

                <div className="space-y-2">
                  <h2 className="text-2xl font-bold">Datos de la Venta</h2>

                  <div className="relative inline-block w-full">
                    <input
                      type="number"
                      value={venta.totalImportePago}
                      onChange={(e) =>
                        setVenta({
                          ...venta,
                          totalImportePago: Number(e.target.value),
                        })
                      }
                      placeholder="Total Importe Pago"
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                    />
                    <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                      Precio Terreno:* S/.{" "}
                      {
                        terrenos.find(
                          (terreno) => terreno.id === venta.idTerreno
                        )?.precio
                      }
                    </label>
                  </div>

                  <div className="relative inline-block w-full">
                    <input
                      type="number"
                      value={venta.pagoImporteInicial}
                      onChange={(e) =>
                        setVenta({
                          ...venta,
                          pagoImporteInicial: Number(e.target.value),
                        })
                      }
                      placeholder="Pago Importe Inicial"
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                    />
                    <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                      Pago Inicial:*
                    </label>
                  </div>

                  <label className="mt-2 w-full">
                    Fecha de Pago Inicial:*
                    <input
                      type="date"
                      value={venta.fechaPagoInicial}
                      onChange={(e) =>
                        setVenta({ ...venta, fechaPagoInicial: e.target.value })
                      }
                      placeholder="Fecha Pago Inicial"
                      required
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                  </label>
                  <label className="mt-2 w-full">
                    Fecha de Compromiso de Pago:*
                    <input
                      type="date"
                      value={venta.fechaPagoFinal}
                      onChange={(e) =>
                        setVenta({ ...venta, fechaPagoFinal: e.target.value })
                      }
                      placeholder="Fecha Pago Final"
                      required
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                  </label>
                </div>
                <button
                  type="submit"
                  className="w-full p-2 text-white bg-green-500 rounded-md hover:bg-green-600"
                >
                  Guardar Venta
                </button>
              </form>
            )}
          </div>
        </Card>
      )}
    </>
  );
};
