import { PlusIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  Title,
  TableBody,
  TableHeaderCell,
  TableCell,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@tremor/react";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import React, { useState, useEffect } from "react";
import axios from "axios";
import loadingImage from "../../../Multimedia/img/loading.gif";
import API_URL from "../../../authentication/api";

function ClientesForm() {
  return (
    <>
      <TabGroup defaultIndex={0} className="dark:bg-stone-300 p-0 m-0 rounded-xl">
        <TabList className="pt-2" color="blue" variant="line">
          <Tab icon={UserGroupIcon}>Lista de Clientes</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TableBase />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </>
  );
}

export default ClientesForm;

const TableBase = () => {
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true); // Iniciar la carga
    axios
      .get(`${API_URL}/clientes`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Hubo un error al obtener los datos:", error);
      })
      .finally(() => {
        setLoading(false); // Finalizar la carga
      });
  }, []);

  const handleEdit = (client) => {
    setEditing(true);
    setCurrentClient(client);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (currentClient.DNI) {
      axios
        .put(`${API_URL}/clientes/${currentClient.id}`, currentClient)
        .then((response) => {
          setEditing(false);
          // Actualizar currentClient con la nueva data
          setCurrentClient(response.data);
          setData(
            data.map((item) =>
              item.DNI === currentClient.id ? currentClient : item
            )
          );
        })
        .catch((error) => {
          console.error("Hubo un error al actualizar los datos:", error);
        });
    } else {
      axios
        .post(`${API_URL}/clientes`, currentClient)
        .then((response) => {
          setEditing(false);
          // Actualizar currentClient con la nueva data
          setCurrentClient(response.data);
          setData([...data, response.data]);
        })
        .catch((error) => {
          console.error("Hubo un error al crear los datos:", error);
        });
    }
  };

  const handleInputChange = (event) => {
    setCurrentClient({
      ...currentClient,
      [event.target.name]: event.target.value,
    });
  };

  const handleNew = () => {
    setEditing(true);
    setCurrentClient({
      DNI: "",
      nombre: "",
      apellido: "",
      direccion: "",
      telefono: "",
    });
  };

  return (
    <Card className="dark:bg-stone-200 ">
      <div className="flex justify-between items-center">
        <Title>Clientes</Title>
        <button
          onClick={handleNew}
          className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2"
        >
          <PlusIcon className="h-6 w-6" />
          <span>Cliente</span>
        </button>{" "}
      </div>

      {loading ? (
        <div className="flex mt-10 items-start justify-center h-screen">
          <img
            width="200px"
            height={"auto"}
            src={loadingImage}
            alt="Cargando..."
            className="rounded-full border border-gray-300 p-1 animate-fade-in-out duration-1000"
          />
        </div>
      ) : (
        <>
          <Table className="mt-2">
            <TableHead className="bg-purple-500 dark:bg-slate-500">
              <TableRow>
                <TableHeaderCell className="text-gray-50 ">DNI</TableHeaderCell>
                <TableHeaderCell className="text-gray-50 ">
                  Nombres
                </TableHeaderCell>
                <TableHeaderCell className="text-gray-50 ">
                  Apellidos
                </TableHeaderCell>
                <TableHeaderCell className="text-gray-50 ">
                  Fecha Creacion
                </TableHeaderCell>
                <TableHeaderCell className="text-gray-50 ">
                  Teléfono
                </TableHeaderCell>
                <TableHeaderCell className="text-gray-50 ">
                  Acciones
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody className="dark:bg-slate-100">
              {data.map((client) => (
                <TableRow key={client.DNI}>
                  <TableCell>{client.DNI}</TableCell>
                  <TableCell>{client.nombres}</TableCell>
                  <TableCell>
                    {client.apellidoPaterno} {client.apellidoMaterno}
                  </TableCell>
                  <TableCell>
                    {new Date(client.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{client.numeroCelular}</TableCell>
                  <TableCell>
                    <PencilSquareIcon
                      onClick={() => handleEdit(client)}
                      className="h-5 w-5 text-purple-500 cursor-pointer"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {editing && (
            <>
              <div className="text-sm md:text-xl fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <div
                    className="fixed inset-0 transition-opacity"
                    onClick={() => setEditing(false)}
                  >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <button
                      onClick={() => setEditing(false)}
                      className="absolute right-0 top-0 m-4 text-gray-500 hover:text-gray-700"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                    <form
                      onSubmit={handleSubmit}
                      className="w-full bg-slate-50  p-6"
                    >
                      <h3 className="font-bold text-center mb-1">
                        Datos del Cliente
                      </h3>

                      <div className="relative inline-block w-full">
                        <input
                          type="text"
                          value={currentClient.DNI}
                          onChange={(e) =>
                            setCurrentClient({
                              ...currentClient,
                              DNI: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                          placeholder="DNI"
                          required
                        />
                        <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                          DNI:*
                        </label>
                      </div>

                      <div className="relative inline-block w-full">
                        <input
                          type="text"
                          value={currentClient.nombres}
                          onChange={(e) =>
                            setCurrentClient({
                              ...currentClient,
                              nombres: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                          placeholder="Nombres"
                          required
                        />
                        <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                          Nombres:*
                        </label>
                      </div>

                      <div className="relative inline-block w-full">
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                          type="text"
                          name="apellidoPaterno"
                          value={currentClient.apellidoPaterno}
                          onChange={handleInputChange}
                          placeholder="Apellido Paterno"
                          required
                        />
                        <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                          Apellido Paterno:*
                        </label>
                      </div>

                      <div className="relative inline-block w-full">
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                          type="text"
                          name="apellidoMaterno"
                          value={currentClient.apellidoMaterno}
                          onChange={handleInputChange}
                          placeholder="Apellido Materno"
                          required
                        />
                        <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                          Apellido Materno:*
                        </label>
                      </div>

                      <div className="relative inline-block w-full">
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                          type="text"
                          name="numeroCelular"
                          value={currentClient.numeroCelular}
                          onChange={handleInputChange}
                          placeholder="Número de Celular"
                          required
                        />
                        <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                          Número de Celular:*
                        </label>
                      </div>

                      <div className="relative inline-block w-full">
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                          type="email"
                          name="email"
                          value={currentClient.email || ""}
                          onChange={handleInputChange}
                          placeholder="Email"
                        />
                        <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                          Email:
                        </label>
                      </div>

                      <div className="relative inline-block w-full">
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                          type="text"
                          name="direccion"
                          value={currentClient.direccion || ""}
                          onChange={handleInputChange}
                          placeholder="Dirección"
                        />
                        <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                          Dirección:
                        </label>
                      </div>

                      <div className="relative inline-block w-full">
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                          type="text"
                          name="estadoCivil"
                          value={currentClient.estadoCivil || ""}
                          onChange={handleInputChange}
                          placeholder="Estado Civil"
                        />
                        <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                          Estado Civil:
                        </label>
                      </div>

                      <div className="mt-4">
                        <label className="block text-xs">
                          Fecha de Nacimiento:
                          <input
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                            type="date"
                            name="fechaNacimiento"
                            value={
                              currentClient.fechaNacimiento
                                ? new Date(currentClient.fechaNacimiento)
                                    .toISOString()
                                    .substring(0, 10)
                                : "" || ""
                            }
                            onChange={handleInputChange}
                            placeholder="Fecha de Nacimiento"
                          />
                        </label>
                      </div>
                      <button
                        type="submit"
                        className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                      >
                        Guardar
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Card>
  );
};
