import React from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import axios from "axios";
import API_URL from "../../authentication/api";
import logoLeones from "../../Multimedia/img/logo.svg";
import logoGoogle from "../../Multimedia/img/google-logo.svg";

function SignIn({ onUserVerified }) {
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Verificar si el correo del usuario coincide con el de un vendedor
      const response = await axios.get(`${API_URL}/vendedores`);
      const vendedor = response.data.find((v) => v.email === user.email);

      if (vendedor) {
        if (vendedor.typeUser === "desactivo") {
          alert("Tu cuenta está desactivada");
        } else {
          onUserVerified(user, vendedor.typeUser);
        }
      } else {
        alert("No eres un vendedor");
      }
    } catch (error) {
      if (error.code === "auth/user-disabled") {
        alert("Tu cuenta ha sido desactivada en Firebase");
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-200">
      <img
        src={logoLeones}
        alt="LeonesDelSur"
        className="mb-4 w-60"
        style={{ filter: "drop-shadow(2px 4px 6px gray)" }}
      />

      <button
        onClick={signInWithGoogle}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 shadow-lg"
      >
        <div className="flex items-center justify-center my-1 ">
          <img src={logoGoogle} alt="LogoGoogle" className="w-10" />
          <span className="ml-2">Iniciar sesión con Google</span>
        </div>
      </button>
    </div>
  );
}

export default SignIn;
