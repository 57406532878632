import {
  AreaChart,
  Title,
  Text,
  Card,
  DonutChart,
  ProgressBar,
  Metric,
  Flex,
} from "@tremor/react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../../authentication/api";

const DashboardBase = () => {
  const [chartData, setChartData] = useState([]);
  const [salesData, setSalesData] = useState([]);

  // Establecer las fechas de inicio y fin a los primeros y últimos días del mes actual por defecto
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    .toISOString()
    .split("T")[0];
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    .toISOString()
    .split("T")[0];

  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);
  const [vendedorData, setVendedorData] = useState([]);

  useEffect(() => {
    axios
      .get(`${api}/ventas/`)
      .then((response) => {
        let data = response.data.reduce((acc, venta) => {
          const date = new Date(venta.fechaPagoInicial);
          const dateString = date.toISOString().split("T")[0]; // Convertir la fecha a una cadena en formato YYYY-MM-DD
          if (!acc[dateString]) {
            acc[dateString] = {
              date: dateString,
              ["Total (S/.) Precio Venta"]: 0,
              ["Total (S/.) Pagos Iniciales"]: 0,
            };
          }
          acc[dateString]["Total (S/.) Precio Venta"] += venta.totalImportePago;
          acc[dateString]["Total (S/.) Pagos Iniciales"] += venta.pagoImporteInicial;
          return acc;
        }, {});

        // Filtrar los datos por fecha si se seleccionaron fechas de inicio y fin
        let filteredResponse = response.data;
        if (startDate && endDate) {
          filteredResponse = response.data.filter(
            (venta) =>
              new Date(venta.fechaPagoInicial) >= new Date(startDate) &&
              new Date(venta.fechaPagoInicial) <= new Date(endDate)
          );
          data = Object.values(data).filter(
            (item) =>
              new Date(item.date) >= new Date(startDate) &&
              new Date(item.date) <= new Date(endDate)
          );
        } else {
          data = Object.values(data);
        }

        setChartData(data);

        const salesByVendedor = filteredResponse.reduce((acc, venta) => {
          if (!acc[venta.idVendedor]) {
            acc[venta.idVendedor] = {
              sales: 0,
              idVendedor: `${venta.Vendedor.nombre}`,
              totalImportePago: 0,
              pagoImporteInicial: 0,
              cantidadClientes: 0,
              cantidadVentas: 0, // Añade esta línea
              cantidadVendidos: 0,
            };
          }
          acc[venta.idVendedor].sales += venta.totalImportePago;
          acc[venta.idVendedor].totalImportePago += venta.totalImportePago;
          acc[venta.idVendedor].pagoImporteInicial += venta.pagoImporteInicial;
          acc[venta.idVendedor].cantidadClientes += 1;
          acc[venta.idVendedor].cantidadVentas += 1; // Añade esta línea
          acc[venta.idVendedor].cantidadVendidos += venta.Terreno.estado === "vendido" ? 1 : 0;
          return acc;
        }, {});

        const formattedData = Object.values(salesByVendedor);
        setSalesData(formattedData);
        setVendedorData(formattedData);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [startDate, endDate]);

  const valueFormatter = function (number) {
    return "S/. " + new Intl.NumberFormat("us").format(number).toString();
  };

  const salesSValueFormatter = (number) =>
    `S/. ${new Intl.NumberFormat("us").format(number).toString()}`;

  const salesValueFormatter = (number) =>
    `${new Intl.NumberFormat("us").format(number).toString()}`;

  return (
    <>
      <Card className="flex flex-wrap mb-2 pb-1 dark:bg-stone-300">
        <div className="flex items-center mb-4">
          <div>
            <label className="mr-2 text-sm text-gray-600 font-semibold">Fecha de inicio:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border border-gray-300 rounded px-2 py-1 mr-2 text-gray-700 bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200"
            />
          </div>
          <div>
            <label className="mr-2 text-sm text-gray-600 font-semibold">Fecha de fin:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border border-gray-300 rounded px-2 py-1 text-gray-700 bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200"
            />
          </div>
        </div>
      </Card>
      <Card className="mb-2 pb-6 dark:bg-stone-300">
        <div className="flex flex-col md:flex-row md:space-x-4">
          <Card className="mb-2 pb-6 md:w-1/2 dark:bg-stone-100">
            <Title>Volumen {"(S/.)"} Pagos Iniciales por vendedor</Title>
            <DonutChart
              className="mt-6"
              data={salesData}
              category="totalImportePago"
              index="idVendedor"
              valueFormatter={salesSValueFormatter}
              colors={["slate", "violet", "indigo", "rose", "cyan", "amber"]}
            />
          </Card>
          <Card className="mb-2 pb-6 md:w-1/2 dark:bg-stone-100">
            <Title>Cantidad de Terrenos Separados / Vendidos por Vendedor</Title>
            <DonutChart
              className="mt-6"
              data={salesData}
              category="cantidadVentas" // Cambia 'sales' por 'cantidadVentas'
              index="idVendedor"
              valueFormatter={salesValueFormatter}
              colors={["rose", "cyan", "amber", "slate", "violet", "indigo"]}
            />
          </Card>
        </div>
      </Card>

      <Card className="mb-2 pb-6 dark:bg-stone-100">
        <Title>Total {"(S/.)"} en Ventas</Title>

        <AreaChart
          className="h-72 mt-4 dark:bg-stone-300"
          data={chartData}
          index="date"
          yAxisWidth={50}
          categories={["Total (S/.) Precio Venta", "Total (S/.) Pagos Iniciales"]}
          colors={["emerald", "gray", "indigo", "cyan"]}
          valueFormatter={valueFormatter}
        />
      </Card>

      <Card className="flex flex-wrap dark:bg-stone-300">
        {vendedorData.map((vendedor) => (
          <Card
            className=" dark:bg-stone-100 sombra max-w-xs mx-auto text-indigo-500 mt-8 w-full md:w-1/2"
            key={vendedor.idVendedor}
          >
            <Text>{vendedor.idVendedor}</Text>
            <Metric>Total Valor Terrenos: S/.{vendedor.totalImportePago}</Metric>
            <Flex className="mt-4">
              <Text className="text-gray-700">
                Total Pagos Iniciales: S/.{vendedor.pagoImporteInicial}
              </Text>
            </Flex>
            <Flex className="">
              <Text className="text-gray-700">
                Total Saldos Pendientes: S/.
                {vendedor.totalImportePago - vendedor.pagoImporteInicial}
              </Text>
            </Flex>
            <Flex className="">
              <Text>Cantidad de Ventas: {vendedor.cantidadClientes}</Text> 
            </Flex>
            <Flex className="">
              <Text>{"( "}            
              <span className="text-green-600"> Concretados: {vendedor.cantidadVendidos}</span> 
              {" - "} 
              <span className="text-amber-600"> Separados: {vendedor.cantidadClientes-vendedor.cantidadVendidos}
              </span>{" )"}
              </Text> 
            </Flex>

            <ProgressBar
              value={
                (100 * vendedor.pagoImporteInicial) / vendedor.totalImportePago
              }
              className="mt-2"
            />
          </Card>
        ))}
      </Card>
    </>
  );
};

export default DashboardBase;
