import React, { useState, useEffect } from "react";
import "./UploadDownloadFiles.css";
import { Card, Grid } from "@tremor/react";
import { ArrowDownTrayIcon, TrashIcon } from "@heroicons/react/24/solid";
import loading from "../../../Multimedia/img/loading.gif";

const FileUploader = () => {
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Nuevo estado para controlar la carga
  const [recargar, setRecargar] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  // Intentare modificar el handleUpload
  const handleUpload = () => {
    setIsLoading(true); // Comienza la carga
    const formData = new FormData();
    formData.append("file", file);

    fetch("https://documentos.leonesdelsurinmobiliaria.com/upload.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setIsLoading(false); // Termina la carga
        const uploadMessageElement = document.getElementById("uploadMessage");
        uploadMessageElement.textContent = data.message;
        if (data.message.includes("SATISFACTORIAMENTE")) {
          uploadMessageElement.style.color = "green";
          setTimeout(() => {
            uploadMessageElement.textContent = "";
          }, 5000);
          handleDownload(); // Actualiza la lista de archivos a descargar
        } else {
          uploadMessageElement.style.color = "red";
          setTimeout(() => {
            uploadMessageElement.textContent = "";
          }, 5000);
        }
      })
      .catch((error) => {
        setIsLoading(false); // Termina la carga incluso si hay un error
        console.error("Error:", error);
        const uploadMessageElement = document.getElementById("uploadMessage");
        uploadMessageElement.textContent = "Error: " + error.message;
        uploadMessageElement.style.color = "red";
      });

    let element = document.getElementById("tuElementoId");
    element.classList.add("fade");

    setTimeout(() => {
      element.classList.remove("fade");
    }, 1000);
  };
  const handleDownload = () => {
    fetch("https://documentos.leonesdelsurinmobiliaria.com/list_files.php")
      .then((response) => response.json())
      .then((data) => {
        setFiles(data);
      })
      .catch((error) => console.error("Error:", error));
    let element = document.getElementById("tuElementoId");
    element.classList.add("fade");

    setTimeout(() => {
      element.classList.remove("fade");
    }, 1000);
  };

  const handleDelete = (filename) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que quieres eliminar este archivo?"
    );
    if (confirmed) {
      fetch(`https://documentos.leonesdelsurinmobiliaria.com/delete.php`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `filename=${filename}`,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const deleteMessageElement = document.getElementById("deleteMessage");
          deleteMessageElement.textContent = data.message;
          if (data.message.includes("SATISFACTORIAMENTE")) {
            deleteMessageElement.style.color = "green";

            // Ocultar el mensaje después de 10 segundos
            setTimeout(() => {
              deleteMessageElement.textContent = "";
            }, 5000);

            setRecargar(!recargar); // Actualiza la lista de archivos a descargar
          } else {
            deleteMessageElement.style.color = "red";
            setTimeout(() => {
              deleteMessageElement.textContent = "";
            }, 5000);
            setRecargar(!recargar)
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          const deleteMessageElement = document.getElementById("deleteMessage");
          deleteMessageElement.textContent = "Error: " + error.message;
          deleteMessageElement.style.color = "red";
        });
    }

    let element = document.getElementById("tuElementoId");
    element.classList.add("fade");

    setTimeout(() => {
      element.classList.remove("fade");
    }, 1000);
  };

  // Llamar a handleDownload cuando el componente se monta
  useEffect(() => {
    handleDownload();
  }, [recargar]);

  return (
    <>
      <Grid
        id="tuElementoId"
        numColsMd={2}
        numColsLg={3}
        marginTop="mt-6"
        gapX="gap-x-6"
        gapY="gap-y-6"
      >
        <div className="flex flex-col items-center justify-start min-h-screen bg-gray-100">
          <div className="p-10 bg-white shadow-md rounded-md min-w-360">
            <h1 className="text-2xl font-bold mb-4">
              Subir y Descargar Archivos
            </h1>
            <Card
              id="SubirArchivos"
              className="bg-slate-50 sombra max-w-xs mx-auto lg:max-w-4xl"
            >
              {" "}
              <h5 className="text-lg font-medium text-gray-700 mt-4">
                Solo se permiten archivos JPG, JPEG, PNG, GIF y PDF.
              </h5>
              <h6 className="text-sm text-gray-500 mb-4">
                Archivos no mayores a 20MB
              </h6>
              {isLoading ? (
                <img
                  src={loading}
                  alt="Cargando..."
                  className="rounded-full border border-gray-300 p-1 animate-fade-in-out duration-1000"
                />
              ) : (
                <div className="flex flex-col space-y-4 animate-fade-in-out duration-1000">
                  <label htmlFor="fileInput" className="text-gray-700">
                    Elija el Archivo:
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    onChange={handleFileChange}
                    className="border border-gray-300 rounded-md p-2"
                  />
                  <p id="uploadMessage"></p>
                  <button
                    onClick={handleUpload}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Subir Archivo
                  </button>
                </div>
              )}
              <div id="uploadMessage"></div>
            </Card>
            <Card className="mt-5 bg-slate-50 sombra max-w-xs mx-auto lg:max-w-4xl">
              <ul className="mt-4 space-y-2">
                {files.map((file, index) => (
                  <li
                    key={index}
                    className="py-2 flex justify-between items-center space-x-2 hover:bg-amber-200 rounded-md even:bg-gray-300 "
                  >
                    <span className="text-gray-700 ml-2">{file.name}</span>
                    <a
                      href={`https://documentos.leonesdelsurinmobiliaria.com/${file.url}`}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-2 py-1 bg-gray-500 text-white rounded hover:bg-green-500 hover:text-white"
                    >
                      <ArrowDownTrayIcon width="12px" />
                    </a>
                  </li>
                ))}
              </ul>
            </Card>
            <Card className="mt-5 bg-slate-50 sombra max-w-xs mx-auto lg:max-w-4xl">
              <h2 className="text-xl font-bold mb-4">Eliminar Archivos</h2>
              <p id="deleteMessage"></p>
              <ul className="mt-4 space-y-2">
                {files.map((file, index) => (
                  <li
                    key={index}
                    className="py-2 flex justify-between items-center space-x-2 hover:bg-amber-200 rounded-md even:bg-gray-300 "
                  >
                    <span className="text-gray-700 ml-2">{file.name}</span>
                    <button
                      onClick={() => handleDelete(file.name)}
                      className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                      <TrashIcon width="12px" />
                    </button>
                  </li>
                ))}
              </ul>
            </Card>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default FileUploader;
