import { PlusIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  Title,
  TableBody,
  TableHeaderCell,
  TableCell,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@tremor/react";
import {
  UserGroupIcon,
  ArrowLongDownIcon,
  ArrowLongUpIcon,
  MapPinIcon,
} from "@heroicons/react/24/solid";
import React, { useState, useEffect } from "react";
import axios from "axios";
import loadingImage from "../../../Multimedia/img/loading.gif";
import API_URL from "../../../authentication/api";

function ProyectosTerrenosForm({ tipoUsuario }) {
  return (
    <TabGroup defaultIndex={0} className="dark:bg-stone-300 p-0 m-0 rounded-xl">
      <TabList className="mt-8" color="blue" variant="line">
        <Tab icon={UserGroupIcon}>Terrenos</Tab>
        {tipoUsuario !== "junior" && <Tab icon={UserGroupIcon}>Proyectos</Tab>}
      </TabList>
      <TabPanels>
        <TabPanel>
          <TableTerrenos tipoUsuario={tipoUsuario} />
        </TabPanel>
        <TabPanel>
          <TableProyectos />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
}
export default ProyectosTerrenosForm;

const TableProyectos = () => {
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentProyect, setCurrentProyect] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAscending, setIsAscending] = useState(true);
  const [sortDirection, setSortDirection] = useState(true);

  const activeProyect = data.filter((item) => item.estado === "activo");
  const completeProyect = data.filter((item) => item.estado === "completado");
  const inactiveProyect = data.filter((item) => item.estado === "inactivo");

  useEffect(() => {
    setLoading(true); // Iniciar la carga
    axios
      .get(`${API_URL}/proyectos`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Hubo un error al obtener los datos:", error);
      })
      .finally(() => {
        setLoading(false); // Finalizar la carga
      });
  }, [refreshData]);

  const handleEdit = (seller) => {
    setEditing(true);
    setCurrentProyect(seller);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (currentProyect.id) {
      axios
        .put(`${API_URL}/proyectos/${currentProyect.id}`, currentProyect)
        .then((response) => {
          setEditing(false);
          setRefreshData(!refreshData);
        })
        .catch((error) => {
          console.error("Hubo un error al actualizar los datos:", error);
        });
    } else {
      axios
        .post(`${API_URL}/proyectos`, currentProyect)
        .then((response) => {
          setEditing(false);
          setRefreshData(!refreshData);
        })
        .catch((error) => {
          console.error("Hubo un error al crear los datos:", error);
        });
    }
  };
  const handleInputChange = (e) => {
    const newNombre = e.target.value.toUpperCase();
    setCurrentProyect({
      ...currentProyect,
      nombre: newNombre,
    });
    if (data.some((data) => data.nombre === newNombre)) {
      setErrorMessage("Este nombre ya existe");
    } else {
      setErrorMessage("");
    }
  };
  const sortData = (field) => {
    const sortedData = [...data].sort((a, b) => {
      let aValue = a[field];
      let bValue = b[field];

      // Verificar si los valores son números
      if (!isNaN(aValue) && !isNaN(bValue)) {
        aValue = Number(aValue);
        bValue = Number(bValue);
      }

      if (aValue < bValue) {
        return sortDirection ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortDirection ? 1 : -1;
      }
      return 0;
    });

    setData(sortedData);
    setSortDirection(!sortDirection);
  };

  return (
    <>
      {loading ? ( // Si está cargando, muestra el componente de carga
        <div className="flex mt-10 items-start justify-center h-screen">
          <img
            width="200px"
            height={"auto"}
            src={loadingImage}
            alt="Cargando..."
            className="rounded-full border border-gray-300 p-1 animate-fade-in-out duration-1000"
          />
        </div>
      ) : (
        // Si no está cargando, muestra el contenido normal
        <Card className="dark:bg-stone-200">
          <div className="flex justify-between items-center">
            <Title>Lista de Proyectos</Title>
            <button
              onClick={() => {
                setEditing(true);
                setCurrentProyect({});
              }}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2"
            >
              <PlusIcon className="h-6 w-6" />
              <span>Proyectos</span>
            </button>
          </div>

          <Table className="mt-2">
            <TableHead className="bg-blue-500 dark:bg-slate-600">
              <TableRow>
                <TableHeaderCell className="text-gray-50">
                  Nombre
                  {isAscending ? (
                    <ArrowLongUpIcon
                      width="15px"
                      onClick={() => {
                        sortData("nombre");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  ) : (
                    <ArrowLongDownIcon
                      width="15px"
                      onClick={() => {
                        sortData("nombre");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  )}
                </TableHeaderCell>
                <TableHeaderCell className="text-gray-50">
                  Distrito
                  {isAscending ? (
                    <ArrowLongUpIcon
                      width="15px"
                      onClick={() => {
                        sortData("distrito");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  ) : (
                    <ArrowLongDownIcon
                      width="15px"
                      onClick={() => {
                        sortData("distrito");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  )}
                </TableHeaderCell>
                <TableHeaderCell className="text-gray-50">
                  Estado
                  {isAscending ? (
                    <ArrowLongUpIcon
                      width="15px"
                      onClick={() => {
                        sortData("estado");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  ) : (
                    <ArrowLongDownIcon
                      width="15px"
                      onClick={() => {
                        sortData("estado");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  )}
                </TableHeaderCell>
                <TableHeaderCell className="text-gray-50">
                  Fecha Inicio
                  {isAscending ? (
                    <ArrowLongUpIcon
                      width="15px"
                      onClick={() => {
                        sortData("fechaInicio");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  ) : (
                    <ArrowLongDownIcon
                      width="15px"
                      onClick={() => {
                        sortData("fechaInicio");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  )}
                </TableHeaderCell>
                <TableHeaderCell className="text-gray-50">Opc.</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...activeProyect, ...completeProyect, ...inactiveProyect].map(
                (item, index) => (
                  <TableRow
                    key={index}
                    className={item.estado === "inactivo" ? "bg-red-100" : ""}
                  >
                    <TableCell className="my-0 py-1 mx-0 px-1 text-m10 lg:text-sm">
                      {item.nombre}
                    </TableCell>

                    <TableCell className="my-0 py-1 mx-0 px-1 text-m10 lg:text-sm text-center">
                      {item.distrito}
                    </TableCell>
                    <TableCell
                      className={`my-0 py-1 mx-0 px-1 text-center text-m10 ${
                        item.estado === "inactivo"
                          ? "text-red-700"
                          : "text-green-500"
                      } text-sm`}
                    >
                      {item.estado}
                    </TableCell>
                    <TableCell className="my-0 py-1 mx-0 px-1 text-m10 lg:text-sm text-center">
                      {item.fechaInicio
                        ? new Date(item.fechaInicio).toLocaleDateString(
                            "es-ES",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "Sin Fecha de Inicio"}
                    </TableCell>
                    <TableCell className="my-0 py-1 mx-0 px-1 text-m10 lg:text-sm text-center">
                      <PencilSquareIcon
                        className="h-5 w-5 text-blue-500 cursor-pointer"
                        onClick={() => handleEdit(item)}
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
          {editing && (
            <div className="text-sm md:text-xl fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setEditing(false)}
                >
                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <button
                    onClick={() => setEditing(false)}
                    className="absolute right-0 top-0 m-4 text-gray-500 hover:text-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <form onSubmit={handleSubmit} className="p-6">
                    <label className="block ">Estado del Proyecto:*</label>
                    <div className="relative inline-block w-full">
                      <select
                        value={currentProyect.estado}
                        onChange={(e) =>
                          setCurrentProyect({
                            ...currentProyect,
                            estado: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                      >
                        <option value="activo">Activo</option>
                        <option value="inactivo">Inactivo</option>
                        <option value="completado">Completado</option>
                      </select>
                    </div>

                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentProyect.nombre}
                        onChange={handleInputChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Nombre"
                        required
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Nombre:*{" "}
                        {errorMessage && (
                          <p className="text-red-500">{errorMessage}</p>
                        )}
                      </label>
                    </div>

                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentProyect.direccion}
                        onChange={(e) =>
                          setCurrentProyect({
                            ...currentProyect,
                            direccion: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Dirección"
                        required
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Dirección:*
                      </label>
                    </div>

                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentProyect.referencia}
                        onChange={(e) =>
                          setCurrentProyect({
                            ...currentProyect,
                            referencia: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Referencia"
                        required
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Referencia:*
                      </label>
                    </div>

                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentProyect.provincia}
                        onChange={(e) =>
                          setCurrentProyect({
                            ...currentProyect,
                            provincia: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Provincia"
                        required
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Provincia:*
                      </label>
                    </div>

                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentProyect.distrito}
                        onChange={(e) =>
                          setCurrentProyect({
                            ...currentProyect,
                            distrito: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Distrito"
                        required
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Distrito*:
                      </label>
                    </div>

                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentProyect.corrdenada}
                        onChange={(e) =>
                          setCurrentProyect({
                            ...currentProyect,
                            corrdenada: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Coordenada"
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Coordenada:
                      </label>
                    </div>

                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentProyect.tipo}
                        onChange={(e) =>
                          setCurrentProyect({
                            ...currentProyect,
                            tipo: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Urbanizacion, Condominio, Residencial, etc."
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Tipo:
                      </label>
                    </div>

                    <div className="relative inline-block w-full mt-3">
                      <label className="block">Fecha de Incio:</label>
                      <input
                        type="date"
                        value={
                          currentProyect.fechaInicio
                            ? new Date(currentProyect.fechaInicio)
                                .toISOString()
                                .substring(0, 10)
                            : ""
                        }
                        onChange={(e) =>
                          setCurrentProyect({
                            ...currentProyect,
                            fechaInicio: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                      />
                    </div>

                    <div className="relative inline-block w-full mt-3">
                      <label className="block">Fecha Fin:</label>
                      <input
                        type="date"
                        value={
                          currentProyect.fechaFin
                            ? new Date(currentProyect.fechaFin)
                                .toISOString()
                                .substring(0, 10)
                            : ""
                        }
                        onChange={(e) =>
                          setCurrentProyect({
                            ...currentProyect,
                            fechaFin: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                      />
                    </div>

                    <div className="text-center">
                      <button
                        disabled={errorMessage === "Este nombre ya existe"}
                        type="submit"
                        className={`${
                          errorMessage === "Este nombre ya existe"
                            ? "bg-slate-400"
                            : "bg-blue-500 hover:bg-blue-700"
                        } mt-4 mx-auto   text-white font-bold py-2 px-4 rounded`}
                      >
                        Guardar cambios
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </Card>
      )}
    </>
  );
};

const TableTerrenos = ({ tipoUsuario }) => {
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [currentTerreno, setCurrentTerreno] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAscending, setIsAscending] = useState(true);
  const [sortDirection, setSortDirection] = useState(true);
  
  const terrenoDisponible = data.filter((item) => item.estado === "disponible");
  const terrenoDesactivo = data.filter((item) => item.estado === "desactivo");
  const terrenoVendido = data.filter((item) => item.estado === "vendido");
  const terrenoReservado = data.filter((item) => item.estado === "reservado");

  // Estado para almacenar los proyectos

  // Función para obtener los proyectos
  useEffect(() => {
    setLoading(true); // Iniciar la carga
    obtenerProyectos();
    axios
      .get(`${API_URL}/terrenos`)
      .then((response) => {
        setDataOriginal(response.data);
        setData(response.data);
      })
      .catch((error) => {
        console.error("Hubo un error al obtener los datos:", error);
      })
      .finally(() => {
        setLoading(false); // Finalizar la carga
      });
  }, [refreshData]);

  const obtenerProyectos = async () => {
    try {
      const response = await axios.get(`${API_URL}/proyectos`);
      setProyectos(response.data);
    } catch (error) {
      console.error("Hubo un error al obtener los proyectos: ", error);
    }
  };
  const handleEdit = (seller) => {
    setEditing(true);
    setCurrentTerreno(seller);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (currentTerreno.id) {
      axios
        .put(`${API_URL}/terrenos/${currentTerreno.id}`, currentTerreno)
        .then((response) => {
          setEditing(false);
          setRefreshData(!refreshData);
        })
        .catch((error) => {
          console.error("Hubo un error al actualizar los datos:", error);
        });
    } else {
      axios
        .post(`${API_URL}/terrenos`, currentTerreno)
        .then((response) => {
          setEditing(false);
          setRefreshData(!refreshData);
        })
        .catch((error) => {
          console.error("Hubo un error al crear los datos:", error);
        });
    }
  };
  const handleInputChange = (e) => {
    const newLote = e.target.value;
    setCurrentTerreno({
      ...currentTerreno,
      lote: newLote,
    });
    // console.log(data)
    // console.log(currentTerreno)
    if (
      data.some(
        (data) =>
          data.manzana === currentTerreno.manzana && data.lote === newLote && Number(data.idProyecto) === Number(currentTerreno.idProyecto)
      )
    ) {
      setErrorMessage("Este nombre ya existe");
    } else {
      setErrorMessage("");
    }
  };
  const sortData = (field) => {
    const sortedData = [...data].sort((a, b) => {
      let aValue = a[field];
      let bValue = b[field];

      // Verificar si los valores son números
      if (!isNaN(aValue) && !isNaN(bValue)) {
        aValue = Number(aValue);
        bValue = Number(bValue);
      }

      if (aValue < bValue) {
        return sortDirection ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortDirection ? 1 : -1;
      }
      return 0;
    });

    setData(sortedData);
    setSortDirection(!sortDirection);
  };
  const handleSelectChange = (e) => {
    if (e.target.value === "") {
      setData(dataOriginal);
      return;
    } else {
      setData(
        dataOriginal.filter(
          (item) => item.idProyecto === Number(e.target.value)
        )
      );
    }
  };

  return (
    <>
      {loading ? ( // Si está cargando, muestra el componente de carga
        <div className="flex mt-10 items-start justify-center h-screen">
          <img
            width="200px"
            height={"auto"}
            src={loadingImage}
            alt="Cargando..."
            className="rounded-full border border-gray-300 p-1 animate-fade-in-out duration-1000"
          />
        </div>
      ) : (
        // Si no está cargando, muestra el contenido normal
        <Card className="dark:bg-stone-200">
          <div className="flex justify-between items-center">
            <Title className="dark:text-amber-500">Lista de Terrenos</Title>
            {tipoUsuario !== 'junior' && (
              <button
                onClick={() => {
                  setEditing(true);
                  setCurrentTerreno({
                    idProyecto: Math.max(
                      ...proyectos.map((proyecto) => proyecto.id)
                    ),
                    estado: "disponible",
                  });
                }}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2"
              >
                <PlusIcon className="h-6 w-6" />
                <span>Terreno</span>
              </button>
            )}
          </div>
          <div className="text-m10 lg:text-sm">
            Filtro por Proyecto:
            <div className="text-black">
              <select
                name="proyectos"
                id="proyectos"
                onChange={handleSelectChange}
                className="mt-1 block w-full pl-2 pr-8 py-1 text-sm border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded-md"
              >
                <option key="0" value="" className="text-gray-500 ">
                  --- Todos ---
                </option>
                {proyectos.map((proyecto) => (
                  <option
                    key={proyecto.id}
                    value={proyecto.id}
                    className="text-gray-900"
                  >
                    {proyecto.nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <Table className="mt-2">
            <TableHead className="bg-blue-500 dark:bg-slate-500">
              <TableRow>
                <TableHeaderCell
                  style={{ color: "white" }}
                  className="my-0 py-2 mx-0 px-1 text-m10 lg:text-sm text-center"
                >
                  Proyecto
                  {isAscending ? (
                    <ArrowLongUpIcon
                      width="15px"
                      onClick={() => {
                        sortData("idProyecto");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  ) : (
                    <ArrowLongDownIcon
                      width="15px"
                      onClick={() => {
                        sortData("idProyecto");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  )}
                </TableHeaderCell>
                <TableHeaderCell
                  style={{ color: "white" }}
                  className="my-0 py-2 mx-0 px-1 text-m10 lg:text-sm text-center"
                >
                  {"["}Mz.
                  {isAscending ? (
                    <ArrowLongUpIcon
                      width="15px"
                      onClick={() => {
                        sortData("manzana");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  ) : (
                    <ArrowLongDownIcon
                      width="15px"
                      onClick={() => {
                        sortData("manzana");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  )}
                  {"] ["}Lt.
                  {isAscending ? (
                    <ArrowLongUpIcon
                      width="15px"
                      onClick={() => {
                        sortData("lote");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  ) : (
                    <ArrowLongDownIcon
                      width="15px"
                      onClick={() => {
                        sortData("lote");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  )}
                  {"]"}
                </TableHeaderCell>
                <TableHeaderCell
                  style={{ color: "white" }}
                  className="my-0 py-2 mx-0 px-1 text-m10 lg:text-sm text-center"
                >
                  {"["}Precio
                  {isAscending ? (
                    <ArrowLongUpIcon
                      width="15px"
                      onClick={() => {
                        sortData("precio");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  ) : (
                    <ArrowLongDownIcon
                      width="15px"
                      onClick={() => {
                        sortData("precio");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  )}
                  {"] ["}Area
                  {isAscending ? (
                    <ArrowLongUpIcon
                      width="15px"
                      onClick={() => {
                        sortData("area");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  ) : (
                    <ArrowLongDownIcon
                      width="15px"
                      onClick={() => {
                        sortData("area");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  )}
                  {"]"}
                </TableHeaderCell>
                <TableHeaderCell
                  style={{ color: "white" }}
                  className="my-0 py-2 mx-0 px-1 text-m10 lg:text-sm text-center"
                >
                  Estado
                  {/* {isAscending ? (
                    <ArrowLongUpIcon
                      width="15px"
                      onClick={() => {
                        sortData("estado");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  ) : (
                    <ArrowLongDownIcon
                      width="15px"
                      onClick={() => {
                        sortData("estado");
                        setIsAscending(!isAscending);
                      }}
                      className="inline ml-2 cursor-pointer"
                    />
                  )} */}
                </TableHeaderCell>
                <TableHeaderCell
                  style={{ color: "white" }}
                  className="my-0 py-2 mx-0 px-1 text-m10 lg:text-sm"
                >
                  Opc
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody className="dark:bg-stone-100">
              {[
                ...terrenoDisponible,
                ...terrenoReservado,
                ...terrenoVendido,
                ...terrenoDesactivo,
              ].map((item, index) => (
                <TableRow
                  key={index}
                  className={item.estado === "desactivo" ? "bg-red-100" : ""}
                >
                  <TableCell className="my-0 py-1 mx-0 px-1 text-m10 lg:text-sm">
                    {item.Proyecto.nombre}
                  </TableCell>
                  <TableCell className="my-0 py-1 mx-0 px-1 text-m10 lg:text-sm text-center">
                    {item.manzana}-{item.lote}
                  </TableCell>
                  <TableCell className="my-0 py-1 mx-0 px-1 text-m10 lg:text-sm text-center">
                    ${item.precio} - {item.area}
                  </TableCell>
                  <TableCell
                    className={`my-0 py-1 mx-0 px-1  text-center lg:text-sm text-m10 ${
                      item.estado === "desactivo"
                        ? "text-red-700"
                        : item.estado === "vendido"
                        ? "text-blue-500"
                        : item.estado === "reservado"
                        ? "text-amber-500"
                        : "text-green-500"
                    }  `}
                  >
                    {item.estado}
                  </TableCell>
                  <TableCell className="my-0 py-1 mx-0 px-1 flex items-center">
                  <MapPinIcon 
                      className="h-5 w-5 text-rose-500 cursor-pointer"
                      onClick={() => {
                        const url = `https://www.google.com/maps/search/${item.Proyecto.corrdenada}`;
                        window.open(url, '_blank');
                      }}
                    />
                    {tipoUsuario !== "junior" && (
                      <PencilSquareIcon
                        className="h-5 w-5 text-blue-500 cursor-pointer mr-2"
                        onClick={() => handleEdit(item)}
                      />
                    )}
                    
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {editing && (
            <div className="text-sm md:text-xl fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setEditing(false)}
                >
                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <button
                    onClick={() => setEditing(false)}
                    className="absolute right-0 top-0 m-4 text-gray-500 hover:text-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <form onSubmit={handleSubmit} className="p-6">
                    <label className="block ">Proyecto:*</label>
                    <div className="relative inline-block w-full">
                      <select
                        value={currentTerreno.idProyecto}
                        onChange={(e) =>
                          setCurrentTerreno({
                            ...currentTerreno,
                            idProyecto: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                      >
                        {proyectos.map((proyecto) => (
                          <option key={proyecto.id} value={proyecto.id}>
                            {proyecto.nombre}
                          </option>
                        ))}
                      </select>
                    </div>

                    <label className="block ">Estado:*</label>
                    <div className="relative inline-block w-full">
                      <select
                        value={currentTerreno.estado}
                        onChange={(e) =>
                          setCurrentTerreno({
                            ...currentTerreno,
                            estado: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                      >
                        <option value="disponible">Disponible</option>
                        <option value="reservado">Reservado</option>
                        <option value="vendido">Vendido</option>
                        <option value="desactivo">Desactivo</option>
                      </select>
                    </div>

                    <div className="relative inline-block ">
                      <input
                        type="text"
                        value={currentTerreno.manzana}
                        onChange={(e) =>
                          setCurrentTerreno({
                            ...currentTerreno,
                            manzana: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Manzana"
                        required
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Manzana:*
                      </label>
                    </div>

                    <div className="relative inline-block ">
                      <input
                        type="text"
                        value={currentTerreno.lote}
                        onChange={handleInputChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Lote"
                        required
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Lote:*{" "}
                        {errorMessage && (
                          <p className="text-red-500">{errorMessage}</p>
                        )}
                      </label>
                    </div>

                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentTerreno.area}
                        onChange={(e) =>
                          setCurrentTerreno({
                            ...currentTerreno,
                            area: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="area"
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        area:
                      </label>
                    </div>

                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentTerreno.perimetro}
                        onChange={(e) =>
                          setCurrentTerreno({
                            ...currentTerreno,
                            perimetro: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Perimetro"
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Perimetro:
                      </label>
                    </div>

                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentTerreno.precio}
                        onChange={(e) =>
                          setCurrentTerreno({
                            ...currentTerreno,
                            precio: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Precio"
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Precio:
                      </label>
                    </div>

                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentTerreno.descripcion}
                        onChange={(e) =>
                          setCurrentTerreno({
                            ...currentTerreno,
                            descripcion: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Descripcion breve del lote"
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Descripcion:
                      </label>
                    </div>

                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentTerreno.imagen}
                        onChange={(e) =>
                          setCurrentTerreno({
                            ...currentTerreno,
                            imagen: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Link de la foto"
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Imagen:
                      </label>
                    </div>

                    {/* ----------ID del CLiente---------------- */}
                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={
                          currentTerreno.Venta &&
                          currentTerreno.Venta[0] &&
                          currentTerreno.Venta[0].Cliente
                            ? currentTerreno.Venta[0].Cliente.DNI +
                              " - " +
                              currentTerreno.Venta[0].Cliente.nombres +
                              " " +
                              currentTerreno.Venta[0].Cliente.apellidoPaterno +
                              " " +
                              currentTerreno.Venta[0].Cliente.apellidoMaterno
                            : ""
                        }
                        readOnly
                        // onChange={(e) =>
                        //   setCurrentTerreno({
                        //     ...currentTerreno,
                        //     idCliente: e.target.value,
                        //   })
                        // }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12 text-red-300"
                        placeholder="Aun no tiene dueño"
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Cliente:
                      </label>
                    </div>

                    <div className="text-center">
                      <button
                        disabled={errorMessage === "Este nombre ya existe"}
                        type="submit"
                        className={`${
                          errorMessage === "Este nombre ya existe"
                            ? "bg-slate-400"
                            : "bg-blue-500 hover:bg-blue-700"
                        } mt-4 mx-auto   text-white font-bold py-2 px-4 rounded`}
                      >
                        Guardar cambios
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </Card>
      )}
    </>
  );
};
