    // Import the functions you need from the SDKs you need
    import { initializeApp } from  'firebase/app';
    import { getAuth, GoogleAuthProvider, signInWithPopup} from  "firebase/auth";

    

    const firebaseConfig = {
      apiKey: "AIzaSyBeWml6HK1RHu8F4VV1tzMM7Zh3inf9n9A",
      authDomain: "my-aplication-project-8ef41.firebaseapp.com",
      projectId: "my-aplication-project-8ef41",
      storageBucket: "my-aplication-project-8ef41.appspot.com",
      messagingSenderId: "1072929281946",
      appId: "1:1072929281946:web:8fc7c2f0ea14fbb4bbcc23"
    };

    // Initialize Firebase
    export const app = initializeApp(firebaseConfig);
    export const auth = getAuth(app);
    export const provider = new GoogleAuthProvider();
    export {signInWithPopup}
    