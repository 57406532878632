import {
  PencilSquareIcon,
  CheckBadgeIcon,
  TrashIcon,
  ArrowLongDownIcon,
} from "@heroicons/react/24/outline";
import jsPDF from "jspdf";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableHeaderCell,
  TableCell,
} from "@tremor/react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import loadingImage from "../../../Multimedia/img/loading.gif";
import API_URL from "../../../authentication/api";
import {
  ArrowLongUpIcon,
  FaceFrownIcon,
  PrinterIcon,
} from "@heroicons/react/24/solid";
import autoTable from "jspdf-autotable";
import {base64Image, base64Asociate} from "../../../Multimedia//base64";


const VendedoresForm = ({ typeUser, correoUser }) => {
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentVenta, setCurrentVenta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nuevoPago, setNuevoPago] = useState(0);
  const activeUsers = data.filter((item) => item.typeUser !== "desactivo");
  const inactiveUsers = data.filter((item) => item.typeUser === "desactivo");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isAscending, setIsAscending] = useState(true);

  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [clienteBuscado, setclienteBuscado] = useState(null);
  const [sortDirection, setSortDirection] = useState(true);

  const sortData = (field) => {
    const sortedData = [...data].sort((a, b) => {
      let aValue = a[field];
      let bValue = b[field];

      // Verificar si los valores son números
      if (!isNaN(aValue) && !isNaN(bValue)) {
        aValue = Number(aValue);
        bValue = Number(bValue);
      }

      if (aValue < bValue) {
        return sortDirection ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortDirection ? 1 : -1;
      }
      return 0;
    });

    setData(sortedData);
    setSortDirection(!sortDirection);
  };
  useEffect(() => {
    
    setLoading(true);

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
      .toISOString()
      .split("T")[0];
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      .toISOString()
      .split("T")[0];

    setFechaInicio(firstDay);
    setFechaFinal(lastDay);

    function filtrarDataPorUsuario(data, typeUser, correoUser) {
      let dataFiltrada = data;
      if (typeUser === "junior" || typeUser === "avanzado") {
        dataFiltrada = dataFiltrada.filter(
          (item) => item.Vendedor.email === correoUser
        );
      }
      dataFiltrada = dataFiltrada.filter((item) => {
        const fechaVenta = new Date(item.fechaPagoInicial);
        const firstDate = new Date(firstDay);
        const lastDate = new Date(lastDay);
        return fechaVenta >= firstDate && fechaVenta <= lastDate;
      });
      return dataFiltrada;
    }

    axios
      .get(`${API_URL}/ventas`)
      .then((response) => {
        const dataFiltrada = filtrarDataPorUsuario(
          response.data,
          typeUser,
          correoUser
        );
        setData(dataFiltrada);
      })
      .catch((error) => {
        console.error("Hubo un error al obtener los datos:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [typeUser, correoUser]);

  const Buscador = (e) => {
    setLoading(true);
    e.preventDefault();

    function filtrarDataPorUsuario(data, typeUser, correoUser) {
      let dataFiltrada = data;
      if (typeUser === "junior" || typeUser === "avanzado") {
        dataFiltrada = dataFiltrada.filter(
          (item) => item.Vendedor.email === correoUser
        );
      }

      if (fechaInicio !== null && fechaFinal !== null) {
        dataFiltrada = dataFiltrada.filter((item) => {
          const fechaVenta = new Date(item.fechaPagoInicial);
          return (
            fechaVenta >= new Date(fechaInicio) &&
            fechaVenta <= new Date(fechaFinal)
          );
        });
      }

      if (clienteBuscado !== null) {
        let clienteBuscadoNormalizado = clienteBuscado.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
        dataFiltrada = dataFiltrada.filter((item) => {
          return (
            item.Cliente.DNI.includes(clienteBuscadoNormalizado) ||
            item.Cliente.nombres.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(clienteBuscadoNormalizado) ||
            item.Cliente.apellidoPaterno.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(clienteBuscadoNormalizado) ||
            item.Cliente.apellidoMaterno.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(clienteBuscadoNormalizado) ||
            item.Vendedor.DNI.includes(clienteBuscadoNormalizado) ||
            item.Vendedor.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(clienteBuscadoNormalizado) ||
            item.Vendedor.apellido.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(clienteBuscadoNormalizado)
          );
        });
      }

      
      

      return dataFiltrada;
    }

    axios
      .get(`${API_URL}/ventas`)
      .then((response) => {
        const dataFiltrada = filtrarDataPorUsuario(
          response.data,
          typeUser,
          correoUser
        );
        setData(dataFiltrada);
      })
      .catch((error) => {
        console.error("Hubo un error al obtener los datos:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (seller) => {
    setEditing(true);
    setCurrentVenta(seller);
    setNuevoPago(seller.pagoImporteInicial);
    console.log(activeUsers)
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedVenta = { ...currentVenta, pagoImporteInicial: nuevoPago };
    setCurrentVenta(updatedVenta);
    setTimeout(() => {
      axios
        .put(`${API_URL}/ventas/${updatedVenta.idVenta}`, updatedVenta)
        .then((response) => {
          setEditing(false);
          setData(
            data.map((item) =>
              item.idVenta === updatedVenta.idVenta ? updatedVenta : item
            )
          );
        })
        .catch((error) => {
          console.error("Hubo un error al actualizar los datos:", error);
        });
    }, 1000);
  };
  const handleDelete = (seller) => {
    const userConfirmation = window.confirm(
      "¿Estás seguro de que quieres eliminar esta venta?"
    );
    if (userConfirmation) {
      try {
        axios
          .delete(`${API_URL}/ventas/${seller.idVenta}`)
          .then((response) => {
            setData(data.filter((item) => item.idVenta !== seller.idVenta));

            setShowSuccessMessage(true);

            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 2000);
          })
          .catch((error) => {
            console.error("Hubo un error al eliminar los datos:", error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };
  function generarBoleta(venta) {
    const diferencia = venta.totalImportePago - venta.pagoImporteInicial;
    const doc = new jsPDF();

    const docWidth = doc.internal.pageSize.getWidth();
    const docHeight = doc.internal.pageSize.getHeight();

    doc.addImage(base64Image, 'PNG', 15, 9, 40, 40);

    let pdfInMM=210;  // Ancho de la página A4 en mm
    let txt1 = "Grupo Inmobiliario";
    let txt2 = "Leones del Sur";
    

    doc.setFontSize(26);
    doc.setFont("helvetica", "bold");

    let txt1Width = doc.getStringUnitWidth(txt1) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    let txt2Width = doc.getStringUnitWidth(txt2) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    

    let txt1X = (pdfInMM - txt1Width) / 2;
    let txt2X = (pdfInMM - txt2Width) / 2;

    doc.text(txt1, txt1X, 20);
    doc.text(txt2, txt2X, 35);

    doc.addImage(base64Asociate, 'PNG', docWidth - 55, 9, 40, 40);

    let txt3 = `Resumen de Venta: N°- 00${venta.idVenta}`;
    doc.setFontSize(15);
    doc.setFont("helvetica", "bold");
    let txt3Width = doc.getStringUnitWidth(txt3) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    let txt3X = (pdfInMM - txt3Width) / 2;
    doc.text(txt3, txt3X, 50);
    

    doc.line(0, docHeight - 70, docWidth, docHeight - 70);

    doc.setFontSize(16);
    doc.text(
      `Cliente: ${venta.Cliente.nombres} ${venta.Cliente.apellidoPaterno} ${venta.Cliente.apellidoMaterno}  |  DNI: ${venta.Cliente.DNI}`,
      20,
      70
    );
    doc.text(`Celular: ${venta.Cliente.numeroCelular}`, 20, 80);

    doc.setFontSize(12);
    doc.line(0, 55, docWidth, 55);
    doc.setFont("helvetica", "italic");
    doc.text(
      `Adquiere en Bien Inmueble: ${venta.Terreno.Proyecto.nombre}`,
      20,
      90
    );
    doc.text(
      `- Proyecto: ${venta.Terreno.Proyecto.nombre}, Mz. ${venta.Terreno.manzana} Lt. ${venta.Terreno.lote}`,
      25,
      100
    );

    const data = [
      {
        concepto: "Costo Total del Terreno",
        monto: `S/. ${venta.totalImportePago.toFixed(2)}`,
      },
      {
        concepto: "Pago Inicial",
        monto: `S/. ${venta.pagoImporteInicial.toFixed(2)}`,
      },
      { concepto: "Monto Pendiente ", monto: `S/. ${diferencia.toFixed(2)}` },
    ];
    doc.autoTable({
      startY: 110,
      head: [["Concepto", "Monto"]],
      body: data.map((d) => [d.concepto, d.monto]),
    });

    doc.text(
      `Fecha de pago inicial: ${new Date(
        venta.fechaPagoInicial
      ).toLocaleDateString()}`,
      20,
      160
    );

    doc.setFontSize(12);
    doc.text(
      `- Ubicado en : ${
        venta.Terreno.Proyecto.provincia +
        " - " +
        venta.Terreno.Proyecto.distrito
      }`,
      25,
      170
    );
    doc.text(`- Referencia : ${venta.Terreno.Proyecto.referencia}`, 25, 180);
    doc.text(`- Dirección : ${venta.Terreno.Proyecto.direccion}`, 25, 190);
    // doc.text(`- Coordenada : ${venta.Terreno.Proyecto.corrdenada}`, 25, 190);
    doc.text(`- Área : ${venta.Terreno.area} m^2`, 25, 200);
    doc.text(`- Perimetro : ${venta.Terreno.perimetro}mtrs.`, 25, 210);
    doc.setFontSize(10);
    doc.text(`- ${venta.Terreno.descripcion}`, 25, 220);

    doc.setFontSize(12);
    doc.text(
      `${venta.Cliente.nombres} ${venta.Cliente.apellidoPaterno} ${venta.Cliente.apellidoMaterno} con DNI ${venta.Cliente.DNI}:
      `,
      15,
      docHeight - 60
    );
    doc.text(
      `${diferencia === 0 ? `Realizo el pago Total el día ${new Date(
        venta.fechaPagoFinal
      ).toLocaleDateString()}, felicidades y gracias por su compra.` : `      Se compromete a realizar el pago  total o parcial el día: ${new Date(
        venta.fechaPagoFinal
      ).toLocaleDateString()}, de lo contrario se 
      procederá a la anulación de la venta y se perderdá el adelanto sin ningún derecho a reclamo.

      El presente documento se genera bajo mutuo acuerdo entre las partes, el día: ${new Date(
        venta.fechaPagoFinal
      ).toLocaleDateString()}
      `} `,
      15,
      docHeight - 50
    );
    

    doc.save(
      `${venta.Cliente.nombres} ${venta.Cliente.apellidoPaterno} ${venta.Cliente.apellidoMaterno}.pdf`
    );
  }

  return (
    <>
      <div className="flex justify-center items-center relative">
        {showSuccessMessage && (
          <div
            id="successMessage"
            className="card text-bg-danger mb-3"
            style={{
              maxWidth: "18rem",
              position: "absolute",
              top: "10vh",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 99999,
            }}
          >
            <div className="card-header flex">
              <FaceFrownIcon width="25px" className="mr-2" />
              Venta Eliminada
            </div>
            <div className="card-body">
              <h5 className="card-title">Venta fue Eliminada</h5>
            </div>
          </div>
        )}
      </div>
      {loading ? (
        <div className="flex mt-10 items-start justify-center h-screen">
          <img
            width="200px"
            height={"auto"}
            src={loadingImage}
            alt="Cargando..."
            className="rounded-full border border-gray-300 p-1 animate-fade-in-out duration-1000"
          />
        </div>
      ) : (
        <Card className="m-auto dark:bg-stone-100">
          <div className="mb-3 flex flex-col md:flex-row md:items-end space-y-2 md:space-y-0 md:space-x-2">
            <div className="flex space-x-2">
              <label className="block text-sm font-medium text-gray-700">
                Fecha Inicio:
                <input
                  type="date"
                  value={fechaInicio}
                  onChange={(e) => setFechaInicio(e.target.value)}
                  className="mt-1 block w-full py-2 px-1 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </label>
              <label className="block text-sm font-medium text-gray-700">
                Fecha Final:
                <input
                  type="date"
                  value={fechaFinal}
                  onChange={(e) => setFechaFinal(e.target.value)}
                  className="mt-1 block w-full py-2 px-1 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </label>
            </div>
            <form 
              onSubmit={(e) => { 
                e.preventDefault(); 
                Buscador(e); 
              }} 
              className="flex space-x-2"
            >
              <input
                type="text"
                placeholder="Busca por Nombre o DNI"
                onChange={(e) => setclienteBuscado(e.target.value)}
                className="text-m12 w-full md:w-auto p-2 mt-1 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <button
                type="submit"
                className="mt-1 w-full py-2 px-3 border border-gray-300 bg-slate-50 text-indigo-500 hover:bg-indigo-500 hover:text-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-600 dark:hover:bg-gray-700 dark:text-slate-50"
              >
                Buscar
              </button>
            </form>
          </div>

          <Table className="mt-2">
            <TableHead className="bg-blue-500 dark:bg-slate-500">
              <TableRow>
                <TableHeaderCell className="md:text-xl text-m10 text-gray-50 text-center px-0">
                  <div style={{ fontSize: "12px" }}>
                    Vendedor
                    {isAscending ? (
                      <ArrowLongUpIcon
                        width="15px"
                        onClick={() => {
                          sortData("idVendedor");
                          setIsAscending(!isAscending);
                        }}
                        className="inline ml-2 cursor-pointer"
                      />
                    ) : (
                      <ArrowLongDownIcon
                        width="15px"
                        onClick={() => {
                          sortData("idVendedor");
                          setIsAscending(!isAscending);
                        }}
                        className="inline ml-2 cursor-pointer"
                      />
                    )}
                  </div>
                </TableHeaderCell>
                <TableHeaderCell className="md:text-xl text-m10 text-gray-50 text-center px-0">
                  <div style={{ fontSize: "12px" }}>
                    Cliente
                    {isAscending ? (
                      <ArrowLongUpIcon
                        width="15px"
                        onClick={() => {
                          sortData("idCliente");
                          setIsAscending(!isAscending);
                        }}
                        className="inline ml-2 cursor-pointer"
                      />
                    ) : (
                      <ArrowLongDownIcon
                        width="15px"
                        onClick={() => {
                          sortData("idCliente");
                          setIsAscending(!isAscending);
                        }}
                        className="inline ml-2 cursor-pointer"
                      />
                    )}
                  </div>
                </TableHeaderCell>
                <TableHeaderCell className="md:text-xl text-m10 text-gray-50 text-center px-0">
                  <div style={{ fontSize: "12px" }}>
                    Pagos
                    {isAscending ? (
                      <ArrowLongUpIcon
                        width="15px"
                        onClick={() => {
                          sortData("pagoImporteInicial");
                          setIsAscending(!isAscending);
                        }}
                        className="inline ml-2 cursor-pointer"
                      />
                    ) : (
                      <ArrowLongDownIcon
                        width="15px"
                        onClick={() => {
                          sortData("pagoImporteInicial");
                          setIsAscending(!isAscending);
                        }}
                        className="inline ml-2 cursor-pointer"
                      />
                    )}
                  </div>
                </TableHeaderCell>
                <TableHeaderCell className="md:text-xl text-m10 text-gray-50 text-center px-0">
                  <div style={{ fontSize: "12px" }}>
                    Estado
                    {isAscending ? (
                      <ArrowLongUpIcon
                        width="15px"
                        onClick={() => {
                          sortData("fechaPagoFinal");
                          setIsAscending(!isAscending);
                        }}
                        className="inline ml-2 cursor-pointer"
                      />
                    ) : (
                      <ArrowLongDownIcon
                        width="15px"
                        onClick={() => {
                          sortData("fechaPagoFinal");
                          setIsAscending(!isAscending);
                        }}
                        className="inline ml-2 cursor-pointer"
                      />
                    )}
                  </div>
                </TableHeaderCell>
                <TableHeaderCell className="md:text-xl text-m10 text-gray-50 text-center px-0">
                  <div style={{ fontSize: "12px" }}>Opc.</div>
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody className="dark:bg-slate-50">
              {[...activeUsers, ...inactiveUsers].map((item, index) => (
                <TableRow key={index} className="my-1">
                  <TableCell className="md:text-m12 text-m9 p-1">
                    <div className="flex flex-col">
                      <div>{item.Vendedor.nombre}</div>
                      <div>{item.Vendedor.apellido}</div>
                    </div>
                  </TableCell>
                  <TableCell
                    className={`md:text-m12 text-m9 p-1 ${
                      item.Terreno.id
                        ? item.totalImportePago - item.pagoImporteInicial === 0
                          ? "text-green-500"
                          : "text-amber-500"
                        : "text-red-700"
                    }`}
                  >
                    <div className="flex flex-col md:text-m12 text-m9 p-1">
                      <span>{item.Cliente.DNI}</span>
                      <span>{item.Cliente.nombres}</span>
                      <span>
                        {item.Cliente.apellidoPaterno}{" "}
                        {item.Cliente.apellidoMaterno}
                      </span>
                      <span>
                        {"-"}
                        {item.Terreno.Proyecto.nombre}
                        {" Mz."}
                        {item.Terreno.manzana}
                        {" Lt."}
                        {item.Terreno.lote}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className="p-1">
                    <div className="flex flex-col md:text-m12 text-m9">
                      <span>Precio Terreno: S/.{item.Terreno.precio}</span>
                      <span>Precio al Cliente: S/.{item.totalImportePago}</span>
                      <span>Pago Inicial: S/.{item.pagoImporteInicial}</span>
                      <span>
                        {item.totalImportePago - item.pagoImporteInicial === 0
                          ? "Venta Concretada"
                          : `Resta S/. ${
                              item.totalImportePago - item.pagoImporteInicial
                            }`}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className="text-center md:text-m12 text-m9 p-1">
                    <div className="flex justify-center">
                      {item.totalImportePago - item.pagoImporteInicial !== 0 ? (
                        <div className="flex flex-col">
                          <span>
                            Fecha Venta:{" "}
                            {
                              new Date(new Date(item.fechaPagoInicial).getTime() + 1000 * 60 * 60 * 24).toLocaleDateString(
                                "es-ES",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )
                            }
                          </span>
                          <span>
                            Fecha Pago:{" "}
                            {new Date(new Date(item.fechaPagoFinal).getTime() + 1000 * 60 * 60 * 24).toLocaleDateString(
                              "es-ES",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )}
                          </span>
                          <span>
                            Vence:{" "}
                            {(() => {
                              const diasRestantes = Math.ceil(
                                (new Date(item.fechaPagoFinal) - new Date()) /
                                  (1000 * 60 * 60 * 24)
                              );
                              return diasRestantes === 0 ? (
                                <span className="text-amber-500">
                                  {" "}
                                  "Vence Hoy"{" "}
                                </span>
                              ) : diasRestantes < 0 ? (
                                <span className="text-red-500">
                                  {" "}
                                  Ya vencio hace {-1*(diasRestantes)} días{" "}
                                </span>
                              ) : (
                                <span className="text-green-500">
                                  {" "}
                                  En {diasRestantes} días{" "}
                                </span>
                              );
                            })()}
                          </span>
                        </div>
                      ) : (
                        <div className="flex flex-col justify-center content-center">
                          <span>
                            Fecha Venta:{" "}
                            {new Date(new Date(item.fechaPagoInicial).getTime() + 1000 * 60 * 60 * 24).toLocaleDateString(
                                "es-ES",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )}
                          </span>
                          <CheckBadgeIcon
                            width="25px"
                            className="text-green-700 block mx-auto"
                          />
                        </div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="md:text-m12 text-m9 ">
                    <div className="p-1 flex items-center justify-center">
                      <PencilSquareIcon
                        className="h-5 w-5 text-blue-500 cursor-pointer"
                        onClick={() => handleEdit(item)}
                      />
                      <PrinterIcon
                        className="h-5 w-5 text-blue-500 cursor-pointer"
                        onClick={() => generarBoleta(item)}
                      />
                      {typeUser !== "junior" && typeUser !== "avanzado" && (
                        <TrashIcon
                          className="h-5 w-5 text-red-500 cursor-pointer ml-2"
                          onClick={() => handleDelete(item)}
                        />
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {editing && (
            <div className="text-sm md:text-xl fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <button
                    onClick={() => setEditing(false)}
                    className="absolute right-0 top-0 m-1 text-gray-500 hover:text-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <form onSubmit={handleSubmit} className="p-6 mt-2 space-y-4">
                    <div className="flex items-center justify-between p-2 bg-gray-200 rounded-md">
                      <h3 className="font-roboto text-lg">
                        {currentVenta &&
                        currentVenta.Terreno &&
                        currentVenta.Terreno.Proyecto
                          ? currentVenta.Terreno.Proyecto.nombre +
                            " - Mz." +
                            currentVenta.Terreno.manzana +
                            " Lt." +
                            currentVenta.Terreno.lote
                          : ""}
                      </h3>
                    </div>
                    <div className="p-2 bg-gray-200 rounded-md">
                      <h2 className="font-roboto text-lg">
                        Total a Pagar: S/. {currentVenta.totalImportePago}
                      </h2>
                    </div>
                    <div className="p-2 bg-gray-200 rounded-md">
                      <h2 className="font-roboto text-lg">
                        Resta esto: S/
                        {currentVenta.totalImportePago -
                          currentVenta.pagoImporteInicial}
                      </h2>
                      <h2 className="font-roboto text-lg">
                        Ahora Resta: S/{" "}
                        {currentVenta.totalImportePago - Number(nuevoPago)}
                      </h2>
                    </div>
                    <div className="relative p-2 bg-gray-200 rounded-md">
                      <input
                        type="number"
                        onChange={(e) =>
                          setNuevoPago(
                            Number(e.target.value) +
                              Number(currentVenta.pagoImporteInicial)
                          )
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-28"
                        placeholder="Monto a adicionar"
                        required
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Adicionar Pago:*
                      </label>
                    </div>
                    <div className="relative p-2 bg-gray-200 rounded-md">
                      <label className="block text-xs">
                        Nueva Fecha de Pago:*
                      </label>
                      <input
                        type="date"
                        value={new Date(currentVenta.fechaPagoFinal)
                          .toISOString()
                          .substring(0, 10)}
                        onChange={(e) =>
                          setCurrentVenta({
                            ...currentVenta,
                            fechaPagoFinal: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        required
                      />
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="mt-4 mx-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Guardar cambios
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </Card>
      )}
    </>
  );
};

export default VendedoresForm;
