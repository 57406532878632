import { PlusIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  Title,
  TableBody,
  TableHeaderCell,
  TableCell,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@tremor/react";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import React, { useState, useEffect } from "react";
import axios from "axios";
import loadingImage from "../../../Multimedia/img/loading.gif";
import API_URL from "../../../authentication/api";

function VendedoresForm({ tipoUsuario }) {
  return (
    <TabGroup defaultIndex={0} className="dark:bg-stone-300 p-0 m-0 rounded-xl">
      <TabList className="pt-2" color="blue" variant="line">
        <Tab icon={UserGroupIcon}>Lista de Vendedores</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <TableBase tipoUsuario={tipoUsuario} />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
}

export default VendedoresForm;

const TableBase = ({ tipoUsuario }) => {
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentSeller, setCurrentSeller] = useState(null);
  const [loading, setLoading] = useState(false);
  const activeUsers = data.filter((item) => item.typeUser !== "desactivo");
  const inactiveUsers = data.filter((item) => item.typeUser === "desactivo");

  useEffect(() => {
    setLoading(true); // Iniciar la carga
    axios
      .get(`${API_URL}/vendedores`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Hubo un error al obtener los datos:", error);
      })
      .finally(() => {
        setLoading(false); // Finalizar la carga
      });
  }, []);

  const handleEdit = (seller) => {
    setEditing(true);
    setCurrentSeller(seller);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (currentSeller.id) {
      axios
        .put(`${API_URL}/vendedores/${currentSeller.id}`, currentSeller)
        .then((response) => {
          setEditing(false);
          setData(
            data.map((item) =>
              item.id === currentSeller.id ? currentSeller : item
            )
          );
        })
        .catch((error) => {
          console.error("Hubo un error al actualizar los datos:", error);
        });
    } else {
      axios
        .post(`${API_URL}/vendedores`, currentSeller)
        .then((response) => {
          setEditing(false);
          setData([...data, response.data]);
        })
        .catch((error) => {
          console.error("Hubo un error al crear los datos:", error);
        });
    }
  };

  const buscarVendedor = async () => {
    const clienteBuscado = data.find((c) => c.DNI === currentSeller.DNI);

    if (clienteBuscado) {
      setCurrentSeller(clienteBuscado);
    } else {
      alert("Vendedor no encontrado");
      setCurrentSeller({ ...currentSeller, DNI: currentSeller.DNI });
    }
  };

  const usersToMap = tipoUsuario === 'avanzado'
  ? [...activeUsers, ...inactiveUsers].filter(item => item.typeUser !== 'superadmin' && item.typeUser !== 'admin' && item.typeUser !== 'avanzado')
  : [...activeUsers, ...inactiveUsers];

  return (
    <>
      {loading ? ( // Si está cargando, muestra el componente de carga
        <div className="flex mt-10 items-start justify-center h-screen">
          <img
            width="200px"
            height={"auto"}
            src={loadingImage}
            alt="Cargando..."
            className="rounded-full border border-gray-300 p-1 animate-fade-in-out duration-1000"
          />
        </div>
      ) : (
        // Si no está cargando, muestra el contenido normal
        <Card className="dark:bg-stone-200">
          <div className="flex justify-between items-center">
            <Title>Lista de Vendedores</Title>
            <button
              onClick={() => {
                setEditing(true);
                setCurrentSeller({});
              }}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2"
            >
              <PlusIcon className="h-6 w-6" />
              <span>vendedor</span>
            </button>
          </div>

          <Table className="mt-2">
            <TableHead className="bg-blue-500 dark:bg-slate-500">
              <TableRow>
                <TableHeaderCell className="text-gray-50">
                  Nombres
                </TableHeaderCell>
                <TableHeaderCell className="text-gray-50">
                  Estado
                </TableHeaderCell>
                <TableHeaderCell className="text-gray-50">
                  Correo
                </TableHeaderCell>
                <TableHeaderCell className="text-gray-50">Opc</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersToMap.map((item, index) => (
                <TableRow
                  key={index}
                  className={item.typeUser === "desactivo" ? "bg-red-100" : "dark:bg-stone-100"}
                >
                  <TableCell>
                    {item.nombre} {item.apellido}
                  </TableCell>
                  <TableCell
                    className={`${
                      item.typeUser === "desactivo"
                        ? "text-red-700"
                        : "text-green-500"
                    } text-sm`}
                  >
                    {item.typeUser.toUpperCase()}
                  </TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>
                    <PencilSquareIcon
                      className="h-5 w-5 text-blue-500 cursor-pointer"
                      onClick={() => handleEdit(item)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {editing && (
            <div className="text-sm md:text-xl fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setEditing(false)}
                >
                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <button
                    onClick={() => setEditing(false)}
                    className="absolute right-0 top-0 m-1 text-gray-500 hover:text-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <form onSubmit={handleSubmit} className="p-6 mt-2">
                    <div className="relative inline-block w-full">
                      <div className="flex">
                        <input
                          type="text"
                          value={currentSeller.DNI}
                          onChange={(e) =>
                            setCurrentSeller({
                              ...currentSeller,
                              DNI: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                          placeholder="DNI"
                          required
                        />

                        <button
                          type="button"
                          onClick={buscarVendedor}
                          className="ml-1 px-1 py-2 mb-1 text-white bg-blue-500 rounded-md hover:bg-blue-600"
                        >
                          Buscar
                        </button>
                      </div>
                    </div>

                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentSeller.nombre}
                        onChange={(e) =>
                          setCurrentSeller({
                            ...currentSeller,
                            nombre: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Nombre"
                        required
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Nombre:*
                      </label>
                    </div>
                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentSeller.apellido}
                        onChange={(e) =>
                          setCurrentSeller({
                            ...currentSeller,
                            apellido: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Apellidos"
                        required
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Apellidos:*
                      </label>
                    </div>
                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentSeller.email}
                        onChange={(e) =>
                          setCurrentSeller({
                            ...currentSeller,
                            email: e.target.value.trim().toLowerCase(),
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Email"
                        required
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Email:*
                      </label>
                    </div>
                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentSeller.address}
                        onChange={(e) =>
                          setCurrentSeller({
                            ...currentSeller,
                            address: e.target.value.trim().toLowerCase(),
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Dirección"
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Dirección:
                      </label>
                    </div>
                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentSeller.celular}
                        onChange={(e) =>
                          setCurrentSeller({
                            ...currentSeller,
                            celular: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Celular"
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Celular:
                      </label>
                    </div>

                    <div className="relative inline-block w-full mt-2">
                      <label className="block text-sm ">
                        Tipo de Usuario:*
                      </label>
                      <select
                        value={currentSeller.typeUser}
                        onChange={(e) =>
                          setCurrentSeller({
                            ...currentSeller,
                            typeUser: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                      >
                        {tipoUsuario === "avanzado" ? (
                          <>
                            <option value="junior">Junior</option>
                            <option value="desactivo">Desactivo</option>
                          </>
                        ) : (
                          <>
                            <option value="junior">Junior</option>
                            <option value="avanzado">Avanzado</option>
                            <option value="admin">Admin</option>
                            <option value="desactivo">Desactivo</option>
                          </>
                        )}
                      </select>
                    </div>
                    <div className="text-m10 text-slate-800 pl-2">
                      <div className="text-m10 text-slate-800 pl-2">
                        <span className="text-m10 text-green-800 pl-2">
                          * admin: Dashboard, ventas (eliminar), vendedores,
                          clientes, terrenos
                        </span>
                      </div>
                      <div className="text-m10 text-slate-800 pl-2">
                        <span className="text-m10 text-blue-800 pl-2">
                          * avanzado: ventas, clientes y Terrenos
                        </span>
                      </div>
                      <div className="text-m10 text-slate-800  pl-2">
                        <span className="text-m10 text-purple-800 pl-2">
                          * junior: solo ventas y clientes
                        </span>
                      </div>
                    </div>

                    <div className="relative inline-block w-full mt-2">
                      <label className="block text-sm ">
                        Fecha de Contratación:
                      </label>
                      <input
                        type="date"
                        value={
                          currentSeller.fechaContratacion
                            ? new Date(currentSeller.fechaContratacion)
                                .toISOString()
                                .substring(0, 10)
                            : ""
                        }
                        onChange={(e) =>
                          setCurrentSeller({
                            ...currentSeller,
                            fechaContratacion: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                      />
                    </div>
                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        value={currentSeller.imagen}
                        onChange={(e) =>
                          setCurrentSeller({
                            ...currentSeller,
                            imagen: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-12"
                        placeholder="Link de la foto"
                      />
                      <label className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-gray-400">
                        Imagen:
                      </label>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="mt-4 mx-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Guardar cambios
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </Card>
      )}
    </>
  );
};
