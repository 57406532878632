import "./App.css";
import UserPanel from "./components/Pages/UserPanel";
import React, { useState } from "react";
import SignIn from "./components/Auth/SignIn";

function App() {
  const [userAuth, setUserAuth] = useState(false);
  const [nameUser, setNameUser] = useState(null);
  const [correoUser, setCorreoUser] = useState(null);
  const [typeUser, setTypeUser] = useState(null);

  const handleUserVerified = (user, userType) => {
    setNameUser(user.displayName);
    setCorreoUser(user.email);
    setTypeUser(userType);
    setUserAuth(true);
  };

  return (
    <div className="App bg-neutral-200 dark:bg-zinc-600">
      {userAuth === true ? (
        <>
          <div className="md:text-sm text-m8 shadow-lg w-full bg-gradient-to-r from-blue-800 via-blue-950 to-blue-800 text-white dark:from-gray-800 dark:via-gray-900 dark:to-gray-800 dark:text-gray-200">
            {" "}
            Hola y bienvenido: {nameUser} - {correoUser} [ {typeUser} ]
          </div>{" "}
          <UserPanel
            setUserAuth={setUserAuth}
            typeUser={typeUser}
            correoUser={correoUser}
          />
        </>
      ) : (
        <>
          <SignIn onUserVerified={handleUserVerified} />
        </>
      )}
    </div>
  );
}

export default App;
