import React, { useState, useEffect } from "react";
import "./UserPanel.css";
import PackageIcon from "../../Multimedia/Icons/PackageIcon";
import UsersIcon from "../../Multimedia/Icons/UsersIcon";
import LineChartIcon from "../../Multimedia/Icons/LineChartIcon";
import {
  UserIcon,
  DocumentIcon,
  ChartBarIcon,
} from "@heroicons/react/24/outline";
import Package2Icon from "../../Multimedia/Icons/Package2Icon";
import minLogo from "../../Multimedia/img/minlogo.webp";
import DashboardBase from "./Forms/DashboardBase";
import UploadDownloadFiles from "../../components/Pages/UploadFiles/UploadDownloadFiles";
import VendedoresForm from "../Pages/Forms/VendedoresForm";
import ClientesForm from "../Pages/Forms/ClientesForm";
import ProyectosTerrenosForm from "../Pages/Forms/ProyectosTerrenosForm";
import VentasForm from "../Pages/Forms/VentasForm";

function NavItem({ IconComponent, text, onClick, isSelected }) {
  const additionalClasses = isSelected
  ? "text-gray-900 bg-amber-500/90 dark:bg-amber-400/80 dark:text-white"
  : "block";
  return (
    <button
      className={`flex items-center bg-gray-100/50 rounded-lg px-3 py-1 text-blue-900 transition-all hover:text-gray-100 hover:bg-amber-600/50 dark:hover:bg-slate-900 dark:text-white  gap-1 dark:hover:text-gray-50 ${additionalClasses}`}
      onClick={onClick}
    >
      <IconComponent className="h-4 w-4" />
      {text}
    </button>
  );
}

export default function UserPanel({ setUserAuth, typeUser, correoUser }) {
  const [selectedComponent, setSelectedComponent] = useState(
    typeUser === "superadmin" ? (
      <DashboardBase />
    ) : (
      <VentasForm typeUser={typeUser} correoUser={correoUser} />
    )
  );
  const [selectedNavItem, setSelectedNavItem] = useState("Inicio");

  const handleSignOut = () => {
    setUserAuth(false);
  };

  return (
    <div className="grid min-h-screen w-full overflow-hidden lg:grid-cols-[280px_1fr] dark:bg-blue-800/10">
      <div className="hidden border-r bg-blue-800/80 lg:block dark:bg-gray-800">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex h-[60px] items-center border-b px-6 dark:bg-gray-800">
            <button
              className="flex items-center gap-2 font-semibold "
              style={{ fontSize: "1.2rem", color: "#B5E2FB" }}
            >
              {/* <Logo className="h-11 w-12 btn btn-warning" /> */}
              <img
                className="h-11 w-12 btn btn-warning"
                src={minLogo}
                alt="logo"
              />
              <span className=" text-blue-200 dark:text-yellow-500">Leones Del Sur</span>
            </button>
          </div>
          <div className="flex-1 overflow-auto py-2 bg-blue-900/90 dark:bg-gray-700 ">
            <nav className="gap-2 grid items-start px-4 text-sm font-medium">
              {/* SelectView para movil */}
              <SelectView
                setSelectedComponent={setSelectedComponent}
                setSelectedNavItem={setSelectedNavItem}
                selectedNavItem={selectedNavItem}
                typeUser={typeUser}
                correoUser={correoUser}
              />
            </nav>
          </div>
          <div className="m-auto p-4 ">
            <button
              onClick={handleSignOut}
              className="px-5 py-2 btn btn-warning"
            >
              <p>Cerrar sesión</p>
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <header className="flex h-28 lg:h-[60px] items-center gap-4 border-b bg-blue-800/95 px-6 dark:bg-gray-800">
          <div className="gap-1 lg:hidden w-full flex flex-wrap justify-center items-center">
            {/* SelectView para movil */}
            <SelectView
              setSelectedComponent={setSelectedComponent}
              setSelectedNavItem={setSelectedNavItem}
              selectedNavItem={selectedNavItem}
              typeUser={typeUser}
              correoUser={correoUser}
            />
            <div className="cerrar-sesion">
              <button onClick={handleSignOut} className="btn btn-dark">
                <p style={{ fontSize: ".8rem" }}>Cerrar sesión</p>
              </button>
            </div>
          </div>

          <div className="hidden lg:block ">
            <button className="lg:hidden">
              <Package2Icon className="h-6 w-6" />
            </button>
            <div className="w-full flex-1 ">
              <div className="flex items-center ">
                <h1
                  style={{
                    fontSize: "1.65rem",
                    color: "#B5E2FB",
                    paddingLeft: "20px",
                  }}
                  className="font-semibold text-lg md:text-2xl  text-blue-950 dark:text-yellow-600"
                >
                  {">> "}
                  {selectedNavItem}{" "}
                </h1>
              </div>
            </div>
          </div>
        </header>
        <main className="lg:max-w-custom max-w-custom2 main-deal ">
          <div>{selectedComponent}</div>
        </main>
      </div>
    </div>
  );
}

function SelectView({
  setSelectedComponent,
  setSelectedNavItem,
  selectedNavItem,
  typeUser,
  correoUser,
}) {
  return (
    <>
      {(typeUser === "superadmin" || typeUser === "admin") && (
        <NavItem
          IconComponent={LineChartIcon}
          text="DashBoard"
          onClick={() => {
            setSelectedComponent(
              <>
                {(typeUser === "avanzado" ||
                  typeUser === "admin" ||
                  typeUser === "superadmin") && <DashboardBase />}
              </>
            );
            setSelectedNavItem("DashBoard");
          }}
          isSelected={selectedNavItem === "DashBoard"}
        />
      )}
      {(typeUser === "superadmin" ||
        typeUser === "admin" ||
        typeUser === "avanzado" ||
        typeUser === "junior") && (
        <NavItem
          IconComponent={ChartBarIcon}
          text="Ventas"
          onClick={() => {
            setSelectedComponent(
              <VentasForm typeUser={typeUser} correoUser={correoUser} />
            );
            setSelectedNavItem("Ventas");
          }}
          isSelected={selectedNavItem === "Ventas"}
        />
      )}
      {(typeUser === "superadmin" ||
        typeUser === "admin" ||
        typeUser === "avanzado" ||
        typeUser === "junior") && (
        <NavItem
          IconComponent={PackageIcon}
          text="Terrenos"
          onClick={() => {
            setSelectedComponent(
              <ProyectosTerrenosForm tipoUsuario={typeUser} />
            );
            setSelectedNavItem("Terrenos");
          }}
          isSelected={selectedNavItem === "Terrenos"}
        />
      )}
      {(typeUser === "superadmin" || typeUser === "admin") && (
        <NavItem
          IconComponent={UsersIcon}
          text="Clientes"
          onClick={() => {
            setSelectedComponent(
              <ClientesForm typeUser={typeUser} correoUser={correoUser} />
            );
            setSelectedNavItem("Clientes");
          }}
          isSelected={selectedNavItem === "Clientes"}
        />
      )}
      {(typeUser === "superadmin" ||
        typeUser === "admin" ||
        typeUser === "avanzado") && (
        <NavItem
          IconComponent={UserIcon}
          text="Vendedores"
          onClick={() => {
            setSelectedComponent(<VendedoresForm tipoUsuario={typeUser} />);
            setSelectedNavItem("Vendedores");
          }}
          isSelected={selectedNavItem === "Vendedores"}
        />
      )}
      {(typeUser === "superadmin" ||
        typeUser === "admin" ||
        typeUser === "avanzado") && (
        <NavItem
          IconComponent={DocumentIcon}
          text="Archivos"
          onClick={() => {
            setSelectedComponent(<UploadDownloadFiles />);
            setSelectedNavItem("Archivos");
          }}
          isSelected={selectedNavItem === "Archivos"}
        />
      )}
    </>
  );
}
